import React from "react";
import { useState, useEffect, useRef } from "react";
import MarqueeSection from "@/utils/MarqueeSection";
import Breadcrumbs from "@/utils/Breadcrumbs";
import Head from "next/head";
import { API_URL } from "@/api/search";
import { formatNumber } from "@/utils/formatNumber"; // Adjust the import path as necessary
import { GetServerSideProps } from "next";
import { fetchAllCountries } from "@/api/search"; // Adjust import path as needed
import dynamic from "next/dynamic"; // Import dynamic for code-splitting
const CatagoryCountrySearch = dynamic(
  () => import("@/components/CatagoryCountrySearch")
);
import { getCache, setCache } from "../lib/cache"; // Adjust the path accordingly
import CounterBox from "@/components/CounterBox";
import { fetchAllCategories } from "@/lib/fetchAllCategories";

interface Category {
  id: number;
  categorie_name: string;
}

interface Country {
  i: number;
  s: string;
  n: string;
}

interface Props {
  categories: Category[];
  countries: Country[];
}

// export const  getServerSideProps: GetServerSideProps<Props> = async () => {
//   let categories: Category[] = [];
//   let countries: Country[] = [];
//   let recordCount: any = null;
//   let error: string | null = null;

//   // Fetch categories
//   try {
//     categories = await fetchAllCategories();
//   } catch (fetchCategoriesError) {
//     console.error("Error fetching categories:", fetchCategoriesError);
//     error = "Error fetching categories";
//   }

//   // Fetch countries
//   try {
//     countries = await fetchAllCountries();
//   } catch (fetchCountriesError) {
//     console.error("Error fetching countries:", fetchCountriesError);
//     error = "Error fetching countries";
//   }

//   // Fetch record count
//   try {
//     const response = await fetch(`${API_URL}/b2b/record-count`, {
//       method: "POST", // Ensure this is correct for your API
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     if (!response.ok) {
//       throw new Error("Network response was not ok");
//     }

//     const data = await response.json();

//     if (data.success) {
//       recordCount = data.data;
//     } else {
//       error = "Failed to fetch record count";
//     }
//   } catch (fetchRecordCountError) {
//     error =
//       (fetchRecordCountError as Error).message || "An unknown error occurred";
//   }

//   return {
//     props: {
//       categories,
//       countries,
//       recordCount,
//       error,
//     },
//   };
// };

export const getServerSideProps: GetServerSideProps<Props> = async () => {
  let categories: Category[] = [];
  let countries: Country[] = [];
  let recordCount: any = null;
  let error: string | null = null;

  const cacheKey = "apiDataB2B";

  const cachedData = getCache<{
    categories: Category[];
    countries: Country[];
    recordCount: any;
    error: string | null;
  }>(cacheKey);

  if (cachedData) {
    console.error("cachedData:", cachedData);
    
    ({ categories, countries, recordCount, error } = cachedData);
  } else {
    console.error("else cachedData:", cachedData);
   // Fetch categories
    try {
      categories = await fetchAllCategories();

      // console.log(categories)
    } catch (fetchCategoriesError) {
      console.error('Error fetching categories:', fetchCategoriesError);
      error = 'Error fetching categories';
    }

   // Fetch countries
    try {
      countries = await fetchAllCountries();
    } catch (fetchCountriesError) {
      console.error("Error fetching countries:", fetchCountriesError);
      error = "Error fetching countries";
    }

    // Fetch record count
    try {
      const response = await fetch(`${API_URL}/b2b/record-count`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.success) {
        recordCount = data.data;
      } else {
        error = "Failed to fetch record count";
      }
    } catch (fetchRecordCountError) {
      error =
        (fetchRecordCountError as Error).message || "An unknown error occurred";
    }

    setCache(cacheKey, { categories, countries, recordCount, error });
  }

  return {
    props: {
      categories,
      countries,
      recordCount,
      error,
    },
  };
};

interface DetailsSectionProps {
  title: string;
  subtitle: string;
  text: string;
}

const DetailsSection = ({ title, subtitle, text }: DetailsSectionProps) => {
  const cardRef = useRef<HTMLDivElement>(null);

  return (
    <section
      ref={cardRef}
      className="bg-white border border-slate-300 shadow-lg rounded-2xl p-6 mb-1 text-[var(--text-color)] transition-transform duration-300 ease-in-out transform hover:shadow-xl"
    >
      <div className="text-sm md:text-base mb-4">
        <h3 className="font-semibold gradient-new-text tracking-wide md:text-base text-sm uppercase mb-1">
          {title}
        </h3>
        <h4 className="text-[var(--title-color)] leading-normal text-xl md:text-2xl font-normal mb-1 mt-0">
          {subtitle}
        </h4>
        <div>
          <p className="block my-2 text-[var(--text-color)]">{text}</p>
        </div>
      </div>
    </section>
  );
};

interface DetailsSectionProps {
  type: string;
  title: string;
  subtitle: string;
  text: string;
  imageSrc: any;
  alt: string;
}

interface RecordCountData {
  t_r: number;
  t_e: number;
  t_p: number;
}

interface Props {
  recordCount: RecordCountData | null;
  error: string | null;
  categories: Category[];
  countries: Country[];
}

const B2B = ({ recordCount, error, categories, countries }: Props) => {
  const percentages = [
    {
      title: "85%",
      description: "Increase in lead quality",
    },
    {
      title: "80%",
      description: "Boost in conversion rates",
    },
    {
      title: "90%",
      description: "Increase in customer retention",
    },
  ];

  const sections = [
    {
      id: "1",
      type: "text-image-left",
      title: "Browse B2B Data",
      subtitle: "Search Diverse Business Datasets",
      text: "Whether you need regional insights or specific industry data, our database provider has you covered. We have a wide range of datasets tailored to various categories and locations. Move through our comprehensive B2B data collection to find the perfect business dataset.",
    },
    {
      id: "2",
      type: "text-image-right",
      title: "Preview B2B Leads",
      subtitle: "Download Free Sample B2B Leads",
      text: "Not sure if a B2B dataset is right for you? Download a free sample dataset to evaluate the quality and relevance of our data. We believe in transparency and want you to feel confident in your decision to partner with us as your trusted data provider.",
    },
    {
      id: "3",
      type: "text-image-left",
      title: "Purchase B2B Data",
      subtitle: "Access B2B Data For Sales Teams",
      text: "When you’re ready to dive deeper, buy the complete sales dataset at a competitive price. Our easy dataset download process allows you to access the data in your desired format. Use the datasets to tailor your sales strategies and drive growth with precision.",
    },
    {
      id: "4",
      title: "Business Database",
      subtitle: "Reliable B2B Data Provider",
      text: "Leverage quality data with our regularly updated business database sourced from reputed websites. Data integrity is our priority. Make data-driven business decisions with your go-to data vendor backed by the most current and precise information.",
    },
  ];

  const location = ["Home", "B2B List"];

  const formatNumberWithSpaces = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Format with spaces
  };

  const messages = [
    <>
      {formatNumberWithSpaces(recordCount?.t_r || 0)} Total<span className="gradient-new-text mx-2">Records</span>
    </>,
    <>
      {formatNumberWithSpaces(recordCount?.t_p || 0)} Total <span className="gradient-new-text">Phone Numbers</span>
    </>,
    <>
      {formatNumberWithSpaces(recordCount?.t_e || 0)} Total <span className="gradient-new-text">Emails</span>
    </>,
  ];

  const totalRecords = `${recordCount?.t_r}`;
  const totalPhoneNumbers = `${recordCount?.t_p}`;
  const totalEmails = `${recordCount?.t_e}`;

  const breadcrumbItems = [
    { name: "Home", url: "https://www.bizdataset.com/" },
    // Add more breadcrumb items as needed
    { name: "B2B List", url: "https://www.bizdataset.com/b2b-list" },
  ];

  const b2bIcons = [
    <svg
      key="icon1"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 384 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
    </svg>,
    <svg
      key="icon2"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
    </svg>, // Record icon for page 1
    <svg
      key="icon3"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"></path>
    </svg>, // Phone number icon for page 1
  ];

  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbItems.map((item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: item.name,
      item: item.url ? { "@id": item.url } : undefined,
    })),
  };
  

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <title> B2B Datasets By Category & Location</title>

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbSchema) }}
        />
        <meta
          property="og:title"
          content=" B2B Datasets By Category & Location"
        />
        <meta
          name="twitter:title"
          content=" B2B Datasets By Category & Location"
        />

        <meta
          name="description"
          content="Download free sample dataset now! Find accurate and reliable B2B lead data by location and category. Access business datasets with phone numbers, email addresses, and other important B2B data. Purchase datasets."
        />
        <meta
          property="og:description"
          content="Download free sample dataset now! Find accurate and reliable B2B lead data by location and category. Access business datasets with phone numbers, email addresses, and other important B2B data. Purchase datasets."
        />
        <meta
          name="twitter:description"
          content="Download free sample dataset now! Find accurate and reliable B2B lead data by location and category. Access business datasets with phone numbers, email addresses, and other important B2B data. Purchase datasets."
        />

        <meta name="keywords" content="B2B Database, B2B Leads" />

        <link rel="canonical" href="https://www.bizdataset.com/b2b-list" />
        <meta property="og:url" content="https://www.bizdataset.com/b2b-list" />

        <meta property="og:image" content="https://bizdataset-report.s3.eu-north-1.amazonaws.com/asset/favicon-512x512.png" />
        <meta name="twitter:image" content="https://bizdataset-report.s3.eu-north-1.amazonaws.com/asset/favicon-512x512.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@bizdataset" />

        <meta name="robots" content="index, follow"></meta>
    
      </Head>
      <div className="relative">
        <div className="relative">
          {/* section1 */}
          <section className="relative w-full ">
            {/* Pseudo-element for background image with reduced opacity */}
            <div className="absolute inset-0 mt-14 sm:w-auto md:w-auto lg:w-auto flex items-center justify-center sm:ml-4 md:ml-12 lg:ml-24 background-image-custom"></div>

            <div className=" lg:mt-30 mx-2 md:mx-4 lg:mx-0">
              <div className="mb-3 py-9 w-full flex mt-10 items-center justify-center  flex-col relative z-10">
                <Breadcrumbs location={location} />
                <div className="flex items-center uppercase justify-center tracking-wide text-[20px] text-[var(--title-color)] font-semibold  mb-4 md:mb-2">
                  Business Datasets
                </div>
                <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-center  mb-4 tracking-wide  text-[var(--title-color)]">
                  Access{" "}
                  <span className="gradient-new-text">
                    Ready-Made B2B Leads
                  </span>
                </h1>
                <div className="text-[var(--text-color)] max-w-[750px] text-[16px] text-center lg:text-[18px] leading-relaxed mb-6">
                  <p>
                    Instantly access fresh, accurate, and reliable B2B data,
                    including phone numbers, email addresses, and other
                    essential information with BizDataset.
                  </p>
                </div>

                <CounterBox
                    totalRecords={Number(totalRecords)}
                    totalPhoneNumbers={Number(totalPhoneNumbers)}
                    totalEmails={Number(totalEmails)}
                    messages={messages}
                    showRecords={true}
                    showPhoneNumbers={true}
                    showEmails={true}
                    icons={b2bIcons}
                  />
              </div>
            </div>
          </section>

          {/* section2 */}
          {/* <MarqueeSection /> */}

          {/*search section 1-2 */}
          <div className="content-auto contain-intrinsic-size">
            <CatagoryCountrySearch
              categories={categories}
              countries={countries}
            />
          </div>

          {/* section4 */}
          <div className="max-w-screen-xl mx-auto mt-16 mb-8 px-4 md:px-6 lg:px-8 content-auto contain-intrinsic-size">
            <h2 className="text-2xl lg:text-4xl font-semibold text-center mb-8">
              Reliable{" "}
              <span className="gradient-new-text ">B2B Data Partner</span> To
              Drive Success
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {sections.map((section) => (
                <DetailsSection
                  key={section.id}
                  title={section.title}
                  subtitle={section.subtitle}
                  text={section.text}
                  type={""}
                  imageSrc={undefined}
                  alt={""}
                />
              ))}
            </div>
          </div>

          {/* section5 */}
          <section className="md:pt-12 pb-16   text-[var(--text-color)] content-auto contain-intrinsic-size">
            <div className="box-border ml-auto mr-auto max-w-[1232px] pr-3 pl-3 w-[75%]">
              <div className="text-center text-[var(--title-color)]  pt-10 mb-8">
                <h2 className=" mb-5 mb-xl-8 text-2xl lg:text-4xl font-semibold">
                  Unleash{" "}
                  <span className="gradient-new-text">Business Growth</span>{" "}
                  With Proven Results
                </h2>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
                {percentages.map((card, index) => (
                  <div key={index} className="col-span-1 mb-4">
                    <h3 className="flex flex-col items-center bg-white bg-opacity-50 border rounded-lg shadow-lg p-8 relative border-[#f1f1f1] h-full w-full transition-transform transform  hover:shadow-xl">
                      <div className="mb-4 gradient-new-text font-semibold tracking-wide text-[30px]">
                        <ul>
                          <li>{card.title}</li>
                        </ul>
                      </div>
                      {" "}
                      <div className="body mb-4 text-center font-light text-[18px] leading-6 tracking-wide flex-grow">
                        <ul>
                          <li>{card.description}</li>
                        </ul>
                      </div>
                    </h3>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default B2B;
